import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Preferences } from '@capacitor/preferences';
import { IonIcon, IonImg, isPlatform, useIonRouter } from '@ionic/react';
import { initializeApp } from 'firebase/app';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  browserPopupRedirectResolver,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  linkWithRedirect,
  onAuthStateChanged,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect
} from 'firebase/auth';
import { mailOutline as emailIcon } from 'ionicons/icons';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import logoApple from '../assets/apple.png';
import logoFacebook from '../assets/facebook.png';
import logoGoogle from '../assets/google.png';
import { useLocalStorage } from '../hooks/useLocalStorage';
import {
  clearAuthState,
  getAuthState,
  saveAuthState,
} from '../util/authPersistence';
import constants from '../util/constants';
import { UserContext } from './UserContext';

console.log('Starting Firebase initialization');

// Initialize Firebase - move this into a separate file
const app = initializeApp({
  apiKey: 'AIzaSyDF77wy8VM5U8_LB_48i5E_Q-ySxiPObVE',
  authDomain: 'camino-for-good-co.firebaseapp.com',
  databaseURL: 'https://camino-for-good-co.firebaseio.com',
  projectId: 'camino-for-good-co',
  storageBucket: 'camino-for-good-co.appspot.com',
  messagingSenderId: '594573542603',
  appId: '1:594573542603:web:02b7e0c96a92a70ec7074e',
  measurementId: 'G-S4BR1YTEEN',
});

const auth = getAuth(app);
console.log('Auth initialized');

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const AppleProvider = new OAuthProvider('apple.com');
const GoogleProvider = new GoogleAuthProvider();
const FacebookProvider = new FacebookAuthProvider();

AppleProvider.addScope('email');
AppleProvider.addScope('name');

let userLoggedIn = false;

export type ProviderListItem = {
  appProvider?: string;
  id: string;
  logo: any;
  provider?: any;
  nativeProvider?: any;
  credentialApp?: any;
};

const filteredProviderList = () => {
  const providerList = [
    {
      id: 'google',
      appProvider: 'google.com',
      provider: GoogleProvider,
      nativeProvider: FirebaseAuthentication.signInWithGoogle,
      credentialApp: GoogleAuthProvider,
      logo: <IonImg src={logoGoogle} />,
    },
    {
      id: 'facebook',
      appProvider: 'facebook.com',
      provider: FacebookProvider,
      nativeProvider: FirebaseAuthentication.signInWithFacebook,
      credentialApp: FacebookAuthProvider,
      logo: <IonImg src={logoFacebook} />,
    },
    {
      id: 'iOS',
      appProvider: 'apple.com',
      provider: AppleProvider,
      nativeProvider: FirebaseAuthentication.signInWithApple,
      credentialApp: OAuthProvider,
      logo: <IonImg src={logoApple} />,
    },
    {
      id: 'camino',
      logo: <IonIcon icon={emailIcon} />,
      nativeProvider: FirebaseAuthentication.signInWithEmailAndPassword,
    },
  ];

  return isPlatform('android')
    ? providerList.filter((provider) => provider.id !== 'iOS')
    : providerList;
};

export const ProviderList = filteredProviderList();

interface LoginBody {
  authToken?: string;
  email: string;
  password: string;
}

interface LoginConsumer {
  error: string;
  forgotPassword(email: string): Promise<void>;
  loading: boolean;
  providerLogin(provider: ProviderListItem): Promise<void>;
  providerList: ProviderListItem[];
  login(body: LoginBody & { email: string }): Promise<void>;
  signup(body: LoginBody): Promise<void>;
  validate: Function;
}

const verify = async (body: any = {}) => {
  const idToken = await (body?.authToken
    ? body.authToken
    : auth.currentUser?.getIdToken());
  userLoggedIn = auth.currentUser !== null;

  try {
    const { token } = await fetch(`${constants.ENDPOINT}/auth/verify`, {
      body: JSON.stringify({
        ...body,
      }),
      headers: {
        AuthToken: idToken || '',
        'Content-Type': 'application/json',
      },
      method: 'post',
    }).then((res) => res.json());
    console.log('### token verify', token);
    userLoggedIn = true;
    return token;
  } catch (e) {
    console.error('### Verify error:', e);
  }
  return null;
};

const linkAccounts = async (onError: Function) => {
  const { value: auth_method } = await Preferences.get({
    key: 'auth_method',
  });
  const { value: shouldPromptToLink } = await Preferences.get({
    key: 'auth_link',
  });

  if (
    !!shouldPromptToLink &&
    window.confirm(
      `We have detected two accounts with the same email address. Would you like to merge your ${capitalize(
        shouldPromptToLink
      )} and ${capitalize(auth_method || '')} accounts?`
    )
  ) {
    await Preferences.set({
      key: 'auth_link',
      value: '',
    });

    try {
      const provider = ProviderList.find((p) => p.id === shouldPromptToLink);

      if (provider && provider.provider) {
        await linkWithRedirect(
          auth.currentUser!,
          provider.provider,
          browserPopupRedirectResolver
        );
        return true;
      } else {
        return false;
      }
    } catch (e) {
      onError(e);
    }
    return false;
  }

  await Preferences.set({
    key: 'auth_link',
    value: '',
  });
};

export const LoginContext = createContext<LoginConsumer>({
  loading: true,
} as LoginConsumer);

export const LoginProvider = ({ children }: any) => {
  const router = useIonRouter();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // @ts-ignore - unused variable
  const [get, _set, remove] = useLocalStorage(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const { setToken } = useContext(UserContext);

  const validate = useCallback(
    async (body?: Partial<LoginBody>) => {
      let source = undefined,
        handle = undefined;
      if (
        window.location.pathname.includes('/register/affiliate') ||
        window.location.pathname.includes('/redeem')
      ) {
        const url = window.location.pathname;
        if (url.includes('/register/affiliate')) {
          [source, handle] = url.replace('/register/affiliate/', '').split('/');
        } else {
          [handle] = url.replace('/redeem/', '').split('/');
        }
      }

      const token = await verify({ source, handle, ...body });
      if (token) {
        setToken(token);
      }

      const redirectUrl =
        ((await get('redirectUrl')) as string) || '/dashboard/home';

      if (redirectUrl && window.location.pathname !== '/upgrade') {
        await remove('redirectUrl');
        router.push(redirectUrl);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setToken]
  );

  const handleAuthStateChanged = useCallback(
    async (user?: any) => {
      try {
        setLoading(true);
        const authUser = auth.currentUser;

        if (userLoggedIn) {
          console.log('User already logged in, skipping auth state change');
          return;
        }

        if (authUser?.uid) {
          console.log('Processing auth state change for user:', authUser.email);
          await saveAuthState({
            uid: authUser.uid,
            email: authUser.email,
          });
          await linkAccounts(setError);
          await validate(user);
        } else {
          await clearAuthState();
        }
      } catch (error) {
        console.error('Error handling auth state change:', error);
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    },
    [validate]
  );

  const redirectCallback = useCallback(async () => {
    console.log('Starting auth check');

    try {
      await auth.authStateReady();
      console.log('Auth is ready');

      // Check if we have a stored auth state
      const storedAuthState = await getAuthState();
      if (storedAuthState) {
        console.log('Found stored auth state');
        setLoading(true);
      }

      if (auth.currentUser) {
        console.log('User already authenticated:', auth.currentUser.email);
        setLoading(true);
        await handleAuthStateChanged(auth.currentUser);
      }
    } catch (error: any) {
      console.error('Auth check error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [handleAuthStateChanged]);

  useEffect(() => {
    const initFirebase = async () => {
      console.log('Setting up Firebase Auth');
      let unsubscribe: (() => void) | undefined;

      try {
        // Set persistence first
        await setPersistence(auth, browserSessionPersistence)
          .then(() => console.log('Persistence set'))
          .catch(console.error);

        // Check for stored auth state
        const storedAuthState = await getAuthState();
        if (storedAuthState) {
          console.log('Found stored auth state');
          setLoading(true);
        }

        // Then wait for auth to be ready
        await auth.authStateReady();
        console.log('Auth is ready');

        // Check for redirect result first
        await redirectCallback();

        // Only set up the auth state listener after redirect check is complete
        unsubscribe = onAuthStateChanged(auth, async (user) => {
          try {
            if (user) {
              console.log('Auth state changed: User logged in', user.email);
              await handleAuthStateChanged(user);
            } else {
              console.log('Auth state changed: User logged out');
              await clearAuthState();
              setLoading(false);
            }
          } catch (error) {
            console.error('Error in auth state change handler:', error);
            setError((error as Error).message);
            setLoading(false);
          }
        });
      } catch (error) {
        console.error('Error during Firebase initialization:', error);
        setError((error as Error).message);
        setLoading(false);
      }

      return () => {
        unsubscribe?.();
      };
    };

    initFirebase().catch((error) => {
      console.error('Fatal error during Firebase init:', error);
      setError((error as Error).message);
      setLoading(false);
    });
  }, [handleAuthStateChanged, redirectCallback]);

  const login = async (
    body: LoginBody & { email: string; password: string }
  ) => {
    try {
      const { email, password } = body;
      await signInWithEmailAndPassword(auth, email, password);
      await Preferences.set({
        key: 'auth_method',
        value: 'camino',
      });
      await linkAccounts(setError);
      await validate(body);
    } catch (error) {
      console.error('Login error:', error);
      setError((error as any).message);
    }
  };

  const signup = async (body: LoginBody) => {
    try {
      const { email, password } = body;
      await createUserWithEmailAndPassword(auth, email, password);
      await signInWithEmailAndPassword(auth, email, password);
      await Preferences.set({
        key: 'auth_method',
        value: 'camino',
      });
      await validate(body);
    } catch (error) {
      console.error('Signup error:', error);
      setError((error as any).message);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await Preferences.set({
        key: 'auth_method',
        value: 'camino',
      });
      await sendPasswordResetEmail(auth, email, {
        url: `${process.env.REACT_APP_FRONTEND}/login?email=${email}`,
        handleCodeInApp: false,
      });
    } catch (error) {
      console.error('Forgot password error:', error);
      setError((error as any).message);
    }
  };

  const providerLogin = async (provider: ProviderListItem) => {
    try {
      console.log('Starting provider login for:', provider.id);

      await Preferences.set({
        key: 'auth_method',
        value: provider.id,
      });

      if (isPlatform('cordova') || isPlatform('capacitor')) {
        setLoading(true);
        // Native platform flow
        await provider.nativeProvider();
        const authToken = (await FirebaseAuthentication.getIdToken()).token;
        await validate({ authToken });
      } else {
        // Web platform flow - try popup first, fall back to redirect
        console.log('Starting popup sign-in');
        setIsPopupOpen(true);

        try {
          const result = await signInWithPopup(
            auth,
            provider.provider,
            browserPopupRedirectResolver
          );
          console.log('Popup sign-in successful:', result.user.email);
          setLoading(true);
          await handleAuthStateChanged(result.user);
        } catch (popupError: any) {
          console.log('Popup error:', popupError.code);

          // Handle specific popup errors
          if (
            popupError.code === 'auth/popup-blocked' ||
            popupError.code === 'auth/popup-closed-by-user'
          ) {
            console.log('Popup blocked or closed, falling back to redirect');
            setError('Popup was blocked - redirecting...');

            // Fall back to redirect
            await signInWithRedirect(
              auth,
              provider.provider,
              browserPopupRedirectResolver
            );
            return; // Don't clear loading/popup states since we're redirecting
          }

          // Rethrow other errors
          throw popupError;
        }
      }
    } catch (error: any) {
      console.error('Provider login error:', error);
      if (error.code === 'auth/popup-blocked') {
        setError('Please enable popups for this site to login');
      } else if (error.code === 'auth/cancelled-popup-request') {
        setError('Login cancelled');
      } else {
        setError(error.message);
      }
    } finally {
      // Only clear states if we're not redirecting
      if (!error || (error as any)?.code !== 'auth/popup-blocked') {
        setIsPopupOpen(false);
        setLoading(false);
      }
    }
  };

  return (
    <LoginContext.Provider
      value={{
        error,
        forgotPassword,
        loading: loading && !isPopupOpen,
        login,
        providerList: ProviderList,
        providerLogin,
        signup,
        validate,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
