import { Preferences } from '@capacitor/preferences';

export const AUTH_STATE_KEY = 'firebase_auth_state';

export async function saveAuthState(state: any) {
  await Preferences.set({
    key: AUTH_STATE_KEY,
    value: JSON.stringify(state),
  });
}

export async function getAuthState() {
  const { value } = await Preferences.get({ key: AUTH_STATE_KEY });
  return value ? JSON.parse(value) : null;
}

export async function clearAuthState() {
  await Preferences.remove({ key: AUTH_STATE_KEY });
}
